import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCoutriesData, getStatesById } from "../../../Services/common";
import { getSettings, updateSetting } from "../../../Services/API";


export const getCountries = createAsyncThunk("getCountries", async (data) => {
    try {
        const response = await getCoutriesData();
        return response
    } catch (error) {
        throw error;
    }
});

export const getStatesByCountry = createAsyncThunk("getStatesByCountry", async (data) => {
    try {
        const response = await getStatesById(data);
        return response
    } catch (error) {
        throw error;
    }
});

export const updateSettings = createAsyncThunk("updateSettings", async (data) => {
    try {
        const response = await updateSetting(data);
        return response
    } catch (error) {
        throw error;
    }
});

export const fetchSettings = createAsyncThunk("fetchSettings", async (data) => {
    try {
        const response = await getSettings(data);
        return response
    } catch (error) {
        throw error;
    }
});

 


const initialState = {
    sidebarOffcanvas: false,
    loading: false,
    userDetails: null,
    error: null,
    settings: null,
}
export const commonSlice = createSlice({
    name: "commonSlice",
    initialState,
    reducers: {
        setSidebarOffcanvasShow: (state, action) => {
            state.sidebarOffcanvas = action?.payload
        },
        reset: () => initialState

    },
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.pending, (state) => {
            state.loading = true;
        }).addCase(fetchSettings.fulfilled, (state, action) => {
            state.loading = false;
            state.settings = action.payload?.data?.settings;
        }).addCase(fetchSettings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(updateSettings.pending, (state) => {
            state.loading = true;
        }).addCase(updateSettings.fulfilled, (state, action) => {
            state.loading = false;
            state.settings = action.payload?.data?.settings;
        }).addCase(updateSettings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload; 
        }) 
    },

})
export const { setSidebarOffcanvasShow } = commonSlice.actions
export default commonSlice.reducer;