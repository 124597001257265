import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsers } from "../../../Services/API";
import { pageSize } from "../../../Utils/constants";

export const getUserTable = createAsyncThunk("usersSlice", async (data) => {
    try {
        const response = await getUsers(data);
        return response
    } catch (error) {
        throw error;
    }
});


const initialState = {
    search: "",
    tableData: [],
    page: 1,
    pageSize: pageSize,
    status: "",
    totalPages: 0,
    totalCount: 0,
    startDate: "",
    endDate: "",
    loading: false,
    sortBy: "",
    sortOrder: "",
    pagination: {},
    error: null
}

export const usertable = createSlice({
    name: "usertable",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPagesize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action?.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action?.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action?.payload;
        },
        setDateGlobal: (state, action) => {
            state.startDate = action?.payload?.formattedStartDate;
            state.endDate = action?.payload?.formattedEndDate;
        },
        setStatus: (state, action) => {
            state.status = action?.payload
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getUserTable.pending, (state) => {
            state.loading = true;
        }).addCase(getUserTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableData = action?.payload?.data?.data?.data;
            state.totalPages = action?.payload?.data?.data?.pagination?.totalPages
            state.totalCount = action?.payload?.data?.data?.pagination?.totalCount
            state.pagination = action?.payload?.data?.data?.pagination
        }).addCase(getUserTable.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        })

    }
})
export const { setPage, setSearch, reset,setDateGlobal, setStatus, setSortBy, setSortOrder, setPagesize } = usertable.actions;
export default usertable.reducer;