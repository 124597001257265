import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgotPassword, loginApi, resetPassword } from "../../../Services/API";

// userlogin  Slice
export const loginSlice = createAsyncThunk("userRegister", async (data) => {
    try {
        const response = await loginApi(data);
        return response
    } catch (error) {
        throw error;
    }
});
export const forgetPasswordSlice = createAsyncThunk("forgetPasswordSlice", async (data) => {
    try {
        const response = await forgotPassword(data);
        return response
    } catch (error) {
        throw error;
    }
});
export const resetPasswordSlice = createAsyncThunk("resetPasswordSlice", async (data) => {
    try {
        const response = await resetPassword(data);
        return response
    } catch (error) {
        throw error;
    }
});

const initialState = {
    userlogindetail: [],
    userData: [],
    loading: false,
    error: null
}
export const AuthSclice = createSlice({
    name: "AuthSclice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loginSlice.pending, (state) => {
            state.loading = true;
        })
            .addCase(loginSlice.fulfilled, (state, action) => {
                state.loading = false;
                state.userlogindetail = action.payload;
            })
            .addCase(loginSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

    }
})
export default AuthSclice.reducer;