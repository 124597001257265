import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./slice/AuthSclice"; 
import usersTableSlice from "./slice/usersTableSlice";
import usersDataSlice from "./slice/usersDataSlice";
import casesDataSlice from "./slice/casesDataSlice";
import casesTableSlice from "./slice/casesTableSlice";
import commonSlice from "./slice/commonSlice";
import accountsDataSlice from "./slice/accountsDataSlice";
import accountsTableSlice from "./slice/accountsTableSlice";
import filesTableSlice from "./slice/filesTableSlice";

export const store = configureStore({
    reducer: {
        Auth: AuthSlice, 
        users: usersDataSlice,
        usertable: usersTableSlice,
        cases: casesDataSlice,
        casetable: casesTableSlice,
        accounts: accountsDataSlice,
        accounttable: accountsTableSlice,
        common:commonSlice, 
        files:filesTableSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})