import { commonrequest } from "./commonRequest";

// user loginApi api
export const loginApi = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/login`, data, header);
}
// upload pdf
export const UploadPdfApi = async (data, header) => {
  let IsJson = "form"
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/upload-file`, data, header, IsJson);
}


// downlaod pdf 
export const downloadPdfApi = async (data, header) => {
  return await commonrequest("GET", `${process.env.REACT_APP_API}/api/download-file/${data}`, data, header);
}
  
export const sampleInferIQApi = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/inferIQJson?page=1&pageSize=5`, data, header);
}
 
// Get The Extract Data
export const getExtractDataApi = async (data, header) => {
  return await commonrequest("GET", `${process.env.REACT_APP_API}/api/extraction?pageSize=${data?.pageSize}&page=${data?.page}`, data, header);
}



// api's created by developer after 14th June 2024//


// get Users 
export const getUsers = async (data, header) => {
  const queryParams = [];

  if (data.page) {
    queryParams.push(`page=${data.page}`);
  }
  if (data.pageSize) {
    queryParams.push(`pageSize=${data.pageSize}`);
  }

  if (data.sortBy) {
    queryParams.push(`sortBy=${data.sortBy}`);
  }

  const sortOrder = data.sortOrder;
  if (sortOrder) {
    queryParams.push(`sortOrder=${sortOrder}`);
  }
  const queryString = queryParams.join("&");
  const url = `${process.env.REACT_APP_API}/api/users?${queryString}`;

  return await commonrequest("GET", url, "", header);
};
// get Users 
// create new user api
export const createUser = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/user/create`, data, header);
}
// create new user api

// get user by id 
export const getUserById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/user/${data}`;
  return await commonrequest("GET", url, "", header);
};
// get user by id 

// update user by id 
export const updateUser = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/user/${data?.UserID}`;
  return await commonrequest("PUT", url, data, header);
};
// update user by id 

// delete user by id 
export const deleteUserById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/user/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};
// delete user by id 

// get Cases 
export const getCases = async (data, header) => {
  const queryParams = [];

  if (data.page) {
    queryParams.push(`page=${data.page}`);
  }
  if (data.pageSize) {
    queryParams.push(`pageSize=${data.pageSize}`);
  }

  if (data.sortBy) {
    queryParams.push(`sortBy=${data.sortBy}`);
  }
  if (data.search) {
    queryParams.push(`search=${data.search}`);
  }

  if (data.startDate) {
    queryParams.push(`startDate=${data.startDate}`);
  }

  if (data.endDate) {
    queryParams.push(`endDate=${data.endDate}`);
  }
  const sortOrder = data.sortOrder;
  if (sortOrder) {
    queryParams.push(`sortOrder=${sortOrder}`);
  }
  const queryString = queryParams.join("&");
  const url = `${process.env.REACT_APP_API}/api/cases?${queryString}`;

  return await commonrequest("GET", url, "", header);
};
// get Cases 

// create new case api
export const createCase = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/case/create`, data, header);
}
// create new case api

// get case by id 
export const getCaseById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/case/${data}`;
  return await commonrequest("GET", url, "", header);
};
// get case by id 

// get allcases for filter
export const getCasesData = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/cases/data`;
  return await commonrequest("GET", url, "", header);
};
// get allcases for filter

// update case by id 
export const updateCase = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/case/${data?.id}`;
  return await commonrequest("PUT", url, data, header);
};
// update case by id 

// delete case by id 
export const deleteCaseById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/case/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};
// delete case by id 



// forgot password api
export const forgotPassword = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/forget-password`;
  return await commonrequest("POST", url, data, header);
}
// forgot password api

// resetPassword api
export const resetPassword = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/reset-password`;
  return await commonrequest("POST", url, data, header);
}
// resetPassword api



export const getMe = async (nul) => {
  return await commonrequest("GET", `${process.env.REACT_APP_API}/api/me`, null, nul);
}





// get Cases 
export const getAccounts = async (data, header) => {
  const queryParams = [];

  if (data.page) {
    queryParams.push(`page=${data.page}`);
  }
  if (data.pageSize) {
    queryParams.push(`pageSize=${data.pageSize}`);
  }

  if (data.sortBy) {
    queryParams.push(`sortBy=${data.sortBy}`);
  }
  if (data.search) {
    queryParams.push(`search=${data.search}`);
  }

  if (data.startDate) {
    queryParams.push(`startDate=${data.startDate}`);
  }

  if (data.endDate) {
    queryParams.push(`endDate=${data.endDate}`);
  }
  if (data.payment_plan) {
    queryParams.push(`payment_plan=${data.payment_plan}`);
  }
  const sortOrder = data.sortOrder;
  if (sortOrder) {
    queryParams.push(`sortOrder=${sortOrder}`);
  }
  const queryString = queryParams.join("&");
  const url = `${process.env.REACT_APP_API}/api/accounts?${queryString}`;

  return await commonrequest("GET", url, "", header);
};
// get Accounts 

// create new account api
export const createAccount = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/account/create`, data, header);
}
// create new account api

// get account by id 
export const getAccountById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/account/${data}`;
  return await commonrequest("GET", url, "", header);
};
// get account by id 

// update account by id 
export const updateAccount = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/account/${data?.CustomerID}`;
  return await commonrequest("PUT", url, data, header);
};
// update account by id 

// delete account by id 
export const deleteAccountById = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/account/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};
// delete account by id 



export const getFilesData = async (data, header) => {
  const queryParams = [];

  if (data.status) {
    queryParams.push(`status=${data.status}`);
  }

  if (data.search) {
    queryParams.push(`search=${data.search}`);
  }

  if (data.page) {
    queryParams.push(`page=${data.page}`);
  }
  if (data.pageSize) {
    queryParams.push(`pageSize=${data.pageSize}`);
  }
  if (data.caseNumber) {
    queryParams.push(`caseNumber=${data.caseNumber}`);
  }
  if (data.documentType) {
    queryParams.push(`documentType=${data.documentType}`);
  }

  if (data.startDate) {
    queryParams.push(`startDate=${data.startDate}`);
  }

  if (data.endDate) {
    queryParams.push(`endDate=${data.endDate}`);
  }

  if (data.sortBy) {
    queryParams.push(`sortBy=${data.sortBy}`);
  }

  const sortOrder = data.sortOrder;
  if (sortOrder) {
    queryParams.push(`sortOrder=${sortOrder}`);
  } 

  const queryString = queryParams.join("&");
  const url = `${process.env.REACT_APP_API}/api/files-list?${queryString}`;

  return await commonrequest("GET", url, "", header);
};



// get account by id 
export const getUsersOfCustomer = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/companyusers/${data}`;
  return await commonrequest("GET", url, "", header);
};
// get account by id 


// 
export const upodateJsonFileData = async (data, header) => {
  return await commonrequest("POST", `${process.env.REACT_APP_API}/api/inferIQUpdate`, data, header);
}

export const getAllCompaniesJson = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/companies`;
  return await commonrequest("GET", url, "", header);
};


export const assignUsers = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/assignusers`;
  return await commonrequest("POST", url, data, header);
};


export const getSettings = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/settings`;
  return await commonrequest("GET", url, "", header);
};

export const updateSetting = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/settings`;
  return await commonrequest("POST", url, data, header);
};


export const deleteFile = async (data, header) => {
  const url = `${process.env.REACT_APP_API}/api/file/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};

 
// api's created by developer after 14th June 2024//
