import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UploadPdfApi, deleteFile, downloadPdfApi, getFilesData, sampleInferIQApi, upodateJsonFileData } from "../../../Services/API";
import { pageSize } from "../../../Utils/constants";

export const getFileTable = createAsyncThunk("filessSlice", async (data) => {
    try {
        const response = await getFilesData(data);
        return response
    } catch (error) {
        throw error;
    }
});

export const uploadFileSlice = createAsyncThunk("uploadFileSlice", async (data) => {
    try {
        const response = await UploadPdfApi(data.formData, data.header);
        return response
    } catch (error) {
        throw error;
    }
});


export const downlaodPdfSlice = createAsyncThunk("downlaodPdfSlice", async (data) => {
    try {
        const response = await downloadPdfApi(data);
        return response
    } catch (error) {
        throw error;
    }
});


export const pdfextractData = createAsyncThunk("pdfextractData", async (data) => {
    try {
        const response = await sampleInferIQApi(data);

        return response
    } catch (error) {
        throw error;
    }
});


export const updateFileJson = createAsyncThunk("updateFileJson", async (data) => {
    try {
        const response = await upodateJsonFileData(data);

        return response
    } catch (error) {
        throw error;
    }
});

export const fileDEleteSlice = createAsyncThunk("fileDEleteSlice", async (data) => {
    try {
        const response = await deleteFile(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

const initialState = {
    search: "",
    tableData: [],
    page: 1,
    status: "",
    totalPages: 0,
    totalCount: 0,
    caseNumber: "",
    startDate: "",
    pageSize: pageSize,
    endDate: "",
    loading: false,
    pagination: {},
    sortBy: "",
    sortOrder: "",
    selectedCase: "",
    sampleInferData: [],
    updatess: [],
    apiresponse:null,
    documentType: "",
    error: null,
    data: null,

}
export const fileTable = createSlice({
    name: "fileTable",
    initialState, reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action?.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action?.payload;
        },
        setPagesize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action?.payload;
        },
        setDateGlobal: (state, action) => {
            state.startDate = action?.payload?.formattedStartDate;
            state.endDate = action?.payload?.formattedEndDate;
        },
        setStatus: (state, action) => {
            state.status = action?.payload
        },
        setCaseId: (state, action) => {
            state.caseNumber = action?.payload
        },
        setDocumentType: (state, action) => {
            state.documentType = action?.payload
        },
        setSelectedCase: (state, action) => {
            state.selectedCase = action?.payload
        },
        reset: () => initialState

    },
    extraReducers: (builder) => {
        builder.addCase(getFileTable.pending, (state) => {
            state.loading = true;
        }).addCase(getFileTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableData = action?.payload?.data?.data?.Documents;
            state.totalPages = action?.payload?.data?.data?.pagination?.totalPages
            state.pagination = action?.payload?.data?.data?.pagination
        }).addCase(getFileTable.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }).addCase(downlaodPdfSlice.pending, (state) => {
            state.loading = true;
        }).addCase(downlaodPdfSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(downlaodPdfSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(uploadFileSlice.pending, (state) => {
            state.loading = true;
        }).addCase(uploadFileSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(uploadFileSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(pdfextractData.pending, (state) => {
            state.loading = true;
        }).addCase(pdfextractData.fulfilled, (state, action) => {
            state.page = action?.payload?.data?.pagination?.page;
            state.totalCount = action?.payload?.data?.pagination?.totalCount;
            state.loading = false;
            state.sampleInferData = action?.payload?.data?.inferIQData[0]?.data;
            state.updatess = action?.payload?.data?.updated;
            state.apiresponse = action?.payload?.data?.apiresponse;
        }).addCase(pdfextractData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(updateFileJson.pending, (state) => {
            state.loading = true;
        }).addCase(updateFileJson.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action?.payload?.data;
            state.updatess = [action?.payload?.data?.updated];
        }).addCase(updateFileJson.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(fileDEleteSlice.pending, (state) => {
            state.loading = true;
        }).addCase(fileDEleteSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.caselogindetail = action.payload;
        }).addCase(fileDEleteSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    }
})
export const { setPage, setSearch, reset,setSelectedCase, setPagesize, setDateGlobal, setStatus, setCaseId, setDocumentType, setSortBy, setSortOrder } = fileTable.actions;
export default fileTable.reducer;