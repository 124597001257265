import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCases } from "../../../Services/API";
import { pageSize } from "../../../Utils/constants";

export const getCaseTable = createAsyncThunk("casesSlice", async (data) => {
    try {
        const response = await getCases(data); 
        return response
    } catch (error) { 
        throw error;
    }
});

const initialState = {
    search: "",
    tableData: [],
    page: 1,
    pageSize: pageSize,
    status: "",
    totalPages: 0,
    totalCount: 0,
    startDate: null,
    endDate: null,
    loading: false,
    sortBy: "",
    sortOrder: "",
    pagination: {},
    error: null,
    reset: false,
}
export const casetable = createSlice({
    name: "casetable",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPagesize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action?.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action?.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action?.payload;
        },
        setDateGlobal: (state, action) => {
            state.startDate = action?.payload?.formattedStartDate;
            state.endDate = action?.payload?.formattedEndDate;
        },
        setStatus: (state, action) => {
            state.status = action?.payload
        },
        reset: (state, action) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getCaseTable.pending, (state) => {
            state.loading = true;
        }).addCase(getCaseTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableData = action?.payload?.data?.data?.data;
            state.totalPages = action?.payload?.data?.data?.pagination?.totalPages
            state.pagination = action?.payload?.data?.data?.pagination
        }).addCase(getCaseTable.rejected, (state, action) => {
            // console.log(action?.payload)
            state.loading = false;
            state.error = action?.payload; 
        })

    }
})
export const { setPage, setSearch, setDateGlobal, setStatus, setSortBy, setSortOrder, setPagesize, reset } = casetable.actions;
export default casetable.reducer;