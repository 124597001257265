import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAccount, deleteAccountById, getAccountById, getAllCompaniesJson, updateAccount } from "../../../Services/API";

export const accountsCreateSlice = createAsyncThunk("accountsCreateSlice", async (data) => {
    try {
        const response = await createAccount(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

export const getAccountByIdSlice = createAsyncThunk("getAccountByIdSlice", async (data) => {
    try {
        const response = await getAccountById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const accountDEleteSlice = createAsyncThunk("accountDEleteSlice", async (data) => {
    try {
        const response = await deleteAccountById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const accountsUpdateSlice = createAsyncThunk("accountsUpdateSlice", async (data) => {
    try {
        const response = await updateAccount(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const getAllCompaniesData = createAsyncThunk("getAllCompaniesData", async (data) => {
    try {
        const response = await getAllCompaniesJson(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

const initialState = {
    data: null,
    loading: false,
    error: null
}

export const accountsDataSlice = createSlice({
    name: "accountsDataSlice",
    initialState,
    reducers: { 
        reset: () => initialState

    },
    extraReducers: (builder) => {
        builder.addCase(accountsCreateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(accountsCreateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.accountlogindetail = action.payload;
        }).addCase(accountsCreateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getAccountByIdSlice.pending, (state) => {
            state.loading = true;
        }).addCase(getAccountByIdSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.accountlogindetail = action.payload;
        }).addCase(getAccountByIdSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(accountsUpdateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(accountsUpdateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.accountlogindetail = action.payload;
        }).addCase(accountsUpdateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(accountDEleteSlice.pending, (state) => {
            state.loading = true;
        }).addCase(accountDEleteSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.accountlogindetail = action.payload;
        }).addCase(accountDEleteSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getAllCompaniesData.pending, (state) => {
            state.loading = true;
        }).addCase(getAllCompaniesData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getAllCompaniesData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    },

})
export const { setSidebarOffcanvasShow } = accountsDataSlice.actions
export default accountsDataSlice.reducer;