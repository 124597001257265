import Countries from './country.json';
export const getCoutriesData = () => {
    let _cntries = [];
    Countries.map((_country) => {
        _cntries.push({ "name": _country?.name, "id": _country?.iso3 });
    });
    return _cntries;
}

export const getStatesById = (iso3) => {
    let fetchStates = [];
    Countries.map((_country) => {
        if (_country?.iso3 === iso3) {
            _country?.states?.map((_stt) => {
                fetchStates.push({ "name": _stt?.name, "id": _country?.state_code });
            })
        }
    });
    return fetchStates;
}