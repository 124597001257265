import axios from "axios"; 

export const commonrequest = async (method, url, body, header, isJson = "json") => { 
    let token = localStorage.getItem("token");
    let config = {
        method: method,
        url: url,
        headers: {},
        data: body
    };

    if (isJson && header && header.Authorization) {
        config.headers.Authorization = header.Authorization;
    } else if (token) {
        config.headers.Authorization = token;
    }

    if (header && isJson === "form") {
        config.headers["Content-Type"] = "multipart/form-data";
    } else {
        config.headers["Content-Type"] = "application/json";
    }
    try {
        const response = await axios(config);
        return response;
    } catch (error) {   
        if(error?.response?.status === 401){
            localStorage.removeItem("token"); 
        }else if(error?.code === "ERR_NETWORK"){
            localStorage.removeItem("token"); 
        }
        return error;
    }
};
